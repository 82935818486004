<template>
  <div class="add-form">
    <div class="modal">
      <div
        class="mask"
        @click="close"
      ></div>
      <div class="body">
        <div class="body__top">
          <h2>
            Pdf Category
          </h2>
          <img
            class="close"
            src="~assets/images/close.png"
            alt="close"
            @click="close"
          >
        </div>
        <div class="form">
          <div class="form__item">
            <h4 class="field">Label Category</h4>
            <span class="sep">:</span>
            <input
              class="content"
              type="text"
              placeholder="Label"
              v-model="label"
            >
          </div>
          <div class="form__errors">
            {{errorsText}}
          </div>
          <div class="form__item form__item__end">
            <button
              class="submit"
              @click="handleSubmit"
            >Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'CategoryForm',
  Components: {
    Modal
  },
  data () {
    return {
      errors: [],
      label: ''
    }
  },
  computed: {
    ...mapGetters('user', ['token']),
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('pdf', ['addNewCategories']),
    close () {
      this.$emit('close')
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length > 0) return
      this.addNewCategories({
        data: {
          label: this.label,
          token: this.token
        },
        success: this.handleSuccessSubmit
      })
    },
    handleSuccessSubmit () {
      this.$emit('success')
    },
    validateForm () {
      this.errors = []
      if (this.label === '') this.errors.push(emptyField('Label'))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-form {
  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    .mask {
      background-color: rgba(0,0,0,.6);
      z-index: 8;
      width: 100vw;
      height: 100vh;
    }
    .body {
      width: 60vw;
      height: 90vh;
      margin: auto;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      background-color: white;
      overflow-y: auto;
      padding-bottom: 30px;
      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.6rem;
        .close {
          width: 2.4rem;
          height: 2.4rem;
          cursor: pointer;
        }
      }
      .form {
        font-size: 1.8rem;
        margin: 1.6rem;
        &__item {
          display: flex;
          margin: 1.6rem 0;
          .role {
            margin: 0 4px;
            background-color: white;
            border: 1px solid #b91e27;
            border-radius: 4px;
            padding: 4px;
          }
          .role-active {
            background-color: #b91e27;
            color: #fff
          }
          .check {
            margin: 2px 12px;
            width: 20px;
            height: 20px;
          }
          .field {
            width: 80px;
          }
          .sep {
            margin: 0 8px;
          }
          .content {
            width: 100%;
            margin: 0 16px;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #e3e3e3;
            padding: 0 1rem;
          }
          .area {
            width: 100%;
            margin: 0 16px;
            resize: none;
            height: 100px;
            border: 1px solid #e3e3e3;
            padding: 1rem;
            border-radius: 5px;
          }
          .date {
            width: 100%;
            margin: 0 16px;
            height: 40px;
          }
          &__end {
            justify-content: flex-end;
            margin: 1.6rem;
            .submit {
              padding: 1rem 1.6rem;
            }
          }
        }
        &__errors {
          font-size: 1.4rem;
          color: #B91E27;
          margin: 0 2rem;
        }
      }
    }
  }
}
</style>
